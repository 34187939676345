import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { state } from '../state/state.service';
import { IRailCar } from '../types/railcarLoadingInterfaces';
import { config } from './Home';
import { RailCarService } from '../services/railcar.services';
import { CarSearchForm } from './CarSearchForm';
import { EDIT_CAR_PAGE_ROUTE } from './EditCarPage';

export const VIEW_CARS_PAGE_ROUTE = '/ViewCars';

export function ViewCarPage(): JSX.Element {
  const [searchResults, setSearchResults] = useState<IRailCar[]>([]);
  const [validationMessages, setValidationMessages] = useState<string[]>([]);
  const [showConf, setShowConf] = useState(false);
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  const [searching, setSearching] = useState(false);
  const [deleteSuccessful, setDeleteSuccessful] = useState(false);
  const searchHandler = async (carInitial: string, carNumber: number) => {
    setSearching(true);
    setNoRecordsFound(false);
    setValidationMessages([]);
    setSearchResults([]);
    setDeleteSuccessful(false);
    if (carInitial.trim().length === 0) {
      setValidationMessages(['Car Initial field is required!']);
      setSearching(false);
    } else {
      const railCarService = new RailCarService(axios, config, state);
      const result = await railCarService.getRailCars(carInitial, carNumber);
      if (result) {
        setSearchResults(result);
      } else {
        setNoRecordsFound(true);
      }
      setSearching(false);
    }
  };

  const resetValidationMessages = () => {
    setValidationMessages([]);
  };

  const updateSelectedRailCar = (event: React.MouseEvent) => {
    const eventId = (event.target as HTMLElement).id;
    const splitted = eventId.split('-');
    const selectedRailCar = searchResults.find((obj) => {
      return (
        obj.Initial === splitted[0] && String(obj.CarNumber) === splitted[1]
      );
    });
    if (selectedRailCar) {
      state.selectedRailCar = selectedRailCar;
    }
  };

  const confirmDelete = (event: React.MouseEvent) => {
    updateSelectedRailCar(event);
    setShowConf(true);
  };

  const handleDelete = async () => {
    setSearching(true);
    const railCarService = new RailCarService(axios, config, state);
    if (state.selectedRailCar) {
      const result = await railCarService.deleteRailCar(
        state.selectedRailCar.Initial,
        state.selectedRailCar.CarNumber,
      );
      if (result) {
        setDeleteSuccessful(true);
      }
      const filterdSearchResults = searchResults.filter((obj) => {
        return !(
          obj.Initial === state.selectedRailCar?.Initial &&
          obj.CarNumber === state.selectedRailCar?.CarNumber
        );
      });
      setSearchResults(filterdSearchResults);
      setSearching(false);
    }

    // Delete service call goes here
    setShowConf(false);
  };

  const handleClose = () => {
    setShowConf(false);
  };

  const showReasults = !searching && searchResults.length > 0;
  const validationMessageList = validationMessages.length
    ? validationMessages.map((msg) => {
        return (
          <li className="text-danger text-center font-weight-bold small">
            {msg}
          </li>
        );
      })
    : null;
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          <small className="font-italic font-weight-bold text-primary">
            Home
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/TableAdmin' }}>
          <small className="font-italic font-weight-bold text-primary">
            Table Admin
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <small className="font-italic font-weight-bold text-gray">
            View/Edit/Delete Cars
          </small>
        </Breadcrumb.Item>
      </Breadcrumb>
      <p className="font-weight-bold text-primary text-center mt-1 small">
        ENID NH3 Rail - Find Rail Cars
      </p>
      <div className="d-flex justify-content-center ">
        <CarSearchForm
          searchHandler={searchHandler}
          resetValidationMessages={resetValidationMessages}
        />
      </div>

      {validationMessages.length > 0 && (
        <div className="d-flex justify-content-center ">
          <ul>{validationMessageList}</ul>
        </div>
      )}
      {searching && (
        <div className="d-flex justify-content-center ">
          <Loader
            type="ThreeDots"
            color="#123966"
            height={50}
            width={60}
            timeout={5000} // 5 secs
          />
        </div>
      )}
      {deleteSuccessful && (
        <p className="text-success text-center font-weight-bold mt-1 small">
          Rail car deleted !
        </p>
      )}
      {noRecordsFound && (
        <p className="text-secondary text-center mt-1 small">
          No records found !
        </p>
      )}
      {showReasults && (
        <div className="d-flex justify-content-center ">
          <div className="col-md-7">
            <table className="table table-striped table-responsive-md btn-table">
              <thead>
                <tr>
                  <th className="font-weight-bold text-primary pl-4 small">
                    Car
                  </th>
                  <th className="font-weight-bold text-primary small">
                    Capacity
                  </th>
                  <th className="font-weight-bold text-primary small">
                    Light Weight
                  </th>
                  <th className="font-weight-bold text-primary small">
                    Outage Table Id
                  </th>
                  {state.userRole && state.userRole === 'admin' && (
                    <th className="font-weight-bold text-primary pl-3 small">
                      Edit/Delete
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {searchResults.map((obj) => {
                  return (
                    <tr>
                      <td className="pl-3 font-weight-bold small">
                        {obj.Initial} {obj.CarNumber}
                      </td>
                      <td className="font-weight-bold small">
                        {obj.CarCapacity}
                      </td>
                      <td className="pl-2 font-weight-bold small">
                        {obj.LightWeight}
                      </td>
                      <td className="pl-3 font-weight-bold small">
                        {obj.OutageTableId}
                      </td>
                      {state.userRole && state.userRole === 'admin' && (
                        <td className="pl-2 font-weight-bold small">
                          <Link
                            to={EDIT_CAR_PAGE_ROUTE}
                            id={obj.Initial + -+obj.CarNumber}
                            key={`${obj.Initial}|${obj.CarNumber}`}
                            onClick={updateSelectedRailCar}
                            className="btn btn-outline-primary btn-sm m-0 waves-effect font-weight-bold"
                          >
                            Edit
                          </Link>
                          <button
                            type="button"
                            id={obj.Initial + -+obj.CarNumber}
                            key={`${obj.CarNumber}|${obj.Initial}`}
                            onClick={confirmDelete}
                            className="btn btn-outline-primary btn-sm ml-1 waves-effect font-weight-bold"
                          >
                            Delete
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Modal show={showConf} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {state.selectedRailCar?.Initial}{' '}
          {state.selectedRailCar?.CarNumber} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
