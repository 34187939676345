/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button } from '@kaes/react-components';
import { view } from '@risingstack/react-easy-state';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { RailLoadingHelp } from '../components/RailLoadingHelp';
import { AuthService } from '.';
import { State, state } from '../state/state.service';

interface LoginButtonProps {
  onClick: React.MouseEventHandler;
}

interface AvatarProps {
  onClick: React.MouseEventHandler;
  src: string | undefined;
}

interface LoginComponentProps {
  user: State['user'];
  authService: AuthService;
}

// const Profile: ReactFunction = () => {};
//
export const Avatar = ({ onClick, src }: AvatarProps): JSX.Element => {
  return (
    <img
      height="45px"
      src={src}
      className="rounded-circle pointer mx-3"
      alt="user profile"
      onClick={onClick}
    />
  );
};

export const LoginButton = ({ onClick }: LoginButtonProps): JSX.Element => {
  return (
    <Button float className="btn-outline-danger" onClick={onClick}>
      Login
    </Button>
  );
};

export const LoginComponent = ({
  user,
  authService,
}: LoginComponentProps): JSX.Element => {
  const history = useHistory();
  const login = () => authService.login();
  const redirect = () => history.push('/user-profile');
  if (user) {
    return (
      <>
        <Avatar onClick={redirect} src={user.profile.picture} />
        <RailLoadingHelp />
      </>
    );
  }
  return <LoginButton onClick={login} />;
};

export const Login = view(({ authService }: { authService: AuthService }) => (
  <LoginComponent user={state.user} authService={authService} />
));
