/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-destructuring */

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as yup from 'yup';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import axios from 'axios';
import { state } from '../state/state.service';
import { config } from './Home';
import { RoleService } from '../services/rolemgmt.services';
import { IRole } from '../types/railcarLoadingInterfaces';

export const ASSIGN_ROLE_PAGE_ROUTE = '/Assign';

const options = [
  {
    name: 'Select',
    value: 'NA',
  },
  {
    name: 'Read Only',
    value: 'read-only',
  },
  {
    name: 'Admin',
    value: 'admin',
  },
];

const schema = yup
  .object({
    userId: yup
      .string()
      .email('Please enter a valid email')
      .required('User Id is required'),
    roleName: yup
      .string()
      .required()
      .oneOf(['admin', 'read-only'], 'Please select a role.'),
  })
  .required();

export function AssignRolePage(): JSX.Element {
  const [roleParams, setRoleParams] = useState({
    userId: '',
    roleName: 'NA',
  });
  const [selectedOption, setSelectedOption] = useState(options[0].value);
  const [assigning, setAssigning] = useState(false);
  const [assignmentSuccessful, setAssignmentSuccessful] = useState(false);
  const [currentRoles, setCurrentRoles] = useState('');
  const onSubmit = async () => {
    const roleService = new RoleService(axios, config, state);
    setAssigning(true);
    setAssignmentSuccessful(false);
    setCurrentRoles('');
    const result = await roleService.assignRole(
      roleParams.userId,
      roleParams.roleName,
    );
    let rolesStr = '';
    if (result) {
      if (!_.isEmpty(result.roles)) {
        result.roles.forEach((role: IRole) => {
          if (role.name.includes('aws-railcar-loading-api')) {
            console.log(role.name);
            if (_.isEmpty(rolesStr)) {
              rolesStr = role.name.split(':')[1];
            } else {
              rolesStr = `${rolesStr}, ${role.name.split(':')[1]}`;
            }
          }
        });
        setCurrentRoles(rolesStr);
      }
      setAssigning(false);
      setAssignmentSuccessful(true);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          <small className="font-italic font-weight-bold text-primary">
            Home
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/RoleAdmin' }}>
          <small className="font-italic font-weight-bold text-primary">
            User Role Admin
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <small className="font-italic font-weight-bold text-gray">
            Assign Role
          </small>
        </Breadcrumb.Item>
      </Breadcrumb>
      <p className="font-weight-bold text-primary text-center mt-1 small">
        Assign Role To User
      </p>
      {assigning && (
        <div className="d-flex justify-content-center ">
          <Loader
            type="ThreeDots"
            color="#123966"
            height={50}
            width={60}
            timeout={5000} // 5 secs
          />
        </div>
      )}
      {assignmentSuccessful && (
        <p className="text-success font-weight-bolder text-center mt-1 small">
          Role successfully assigned ! Current Roles: {currentRoles}.
        </p>
      )}
      <div className="d-flex justify-content-center ">
        <div className="col-md-4">
          <form className="card p-1" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mb-0">
              <label
                htmlFor="userId"
                className="text-dark font-weight-bolder small"
              >
                User ID*
              </label>
              <input
                type="text"
                {...register('userId')}
                value={roleParams.userId || ''}
                onChange={(e) => {
                  setRoleParams({
                    ...roleParams,
                    userId: e.target.value,
                  });
                }}
                className="form-control"
                id="userId"
              />
              <p className="text-danger small">{errors.userId?.message}</p>
            </div>
            <div className="form-group mb-0">
              <label
                htmlFor="roleName"
                className="text-dark font-weight-bolder small"
              >
                Role*
              </label>
              <select
                value={selectedOption}
                {...register('roleName')}
                onChange={(e) => {
                  setSelectedOption(e.target.value);
                  setRoleParams({
                    ...roleParams,
                    roleName: e.target.value,
                  });
                }}
                className="form-control"
                id="roleName"
              >
                {options.map((o) => (
                  <option key={o.name} value={o.value}>
                    {o.name}
                  </option>
                ))}
              </select>
              <p className="text-danger small">{errors.roleName?.message}</p>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-sm">
                Assign
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
