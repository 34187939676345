import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import Button from 'react-bootstrap/Button';
import { state } from '../state/state.service';
import { OutageTableForm } from './OutageTableForm';
import { config } from './Home';
import { OutageTableService } from '../services/outagetable.services';
import { OutageTableSearchForm } from './OutageTableSearchForm';

export const NEW_OUTAGE_TABLE_PAGE_ROUTE = '/NewOutageTable';
export function NewOutageTablePage(): JSX.Element {
  const [searching, setSearching] = useState(false);
  const [newOutageTableId, setNewOutageTableId] = useState(0);
  const [showDataEntryForm, setShowDataEntryForm] = useState(false);
  const [showConf, setShowConf] = useState(false);
  const [validationMessages, setValidationMessages] = useState<string[]>([]);
  const resetValidationMessages = () => {
    setValidationMessages([]);
  };

  const searchOutageTableHandler = async (outageTableId: number) => {
    setSearching(true);
    resetValidationMessages();
    if (outageTableId === 0) {
      setValidationMessages(['Outage Table ID is required!']);
    } else {
      const outageTableService = new OutageTableService(axios, config, state);
      const result = await outageTableService.getOutageTable(outageTableId);
      if (result) {
        setValidationMessages(['Outage Table ID already exists!']);
      } else {
        setShowConf(true);
        setNewOutageTableId(outageTableId);
      }
    }
    setSearching(false);
  };

  const addValidationMessage = (message: string) => {
    setValidationMessages([...validationMessages, message]);
  };

  const handleCreate = () => {
    setShowDataEntryForm((!searching && newOutageTableId) as boolean);
    setShowConf(false);
  };

  const handleClose = () => {
    setShowConf(false);
  };

  const validationMessageList = validationMessages.length
    ? validationMessages.map((msg) => {
        return (
          <li className="text-danger text-center font-weight-bold small">
            {msg}
          </li>
        );
      })
    : null;
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          <small className="font-italic font-weight-bold text-primary">
            Home
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/TableAdmin' }}>
          <small className="font-italic font-weight-bold text-primary">
            Table Admin
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <small className="font-italic font-weight-bold text-gray">
            New Outage Table
          </small>
        </Breadcrumb.Item>
      </Breadcrumb>
      <p className="font-weight-bold text-primary text-center mt-1 small">
        ENID NH3 Rail - Create New Outage Table
      </p>
      <div className="d-flex justify-content-center ">
        <OutageTableSearchForm
          searchOutageTableHandler={searchOutageTableHandler}
          resetValidationMessages={resetValidationMessages}
          initVal="Outage Table ID ..."
        />
      </div>
      {validationMessages.length > 0 && (
        <div className="d-flex justify-content-center ">
          <ul>{validationMessageList}</ul>
        </div>
      )}
      {searching && (
        <div className="d-flex justify-content-center ">
          <Loader
            type="ThreeDots"
            color="#123966"
            height={50}
            width={60}
            timeout={5000} // 5 secs
          />
        </div>
      )}
      {showDataEntryForm && (
        <OutageTableForm
          isEdit={false}
          outageTableId={newOutageTableId}
          validationMessageFn={addValidationMessage}
          resetValidationMessages={resetValidationMessages}
          startingInchesValue={config.OUTAGE_TABLE_INCHES_MIN}
          endingInchesValue={config.OUTAGE_TABLE_INCHES_MAX}
        />
      )}
      <Modal show={showConf} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>ID Verified</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          New Outage Table can be created with {newOutageTableId}.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreate}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
