/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { AxiosResponse, AxiosStatic } from 'axios';
import type { State } from '../state/state.service';
import { authService } from '../app.module';
import { ConfigService } from '../config/config.service';
import BaseService from './base.services';

export interface HealthCheck {
  health: {
    status: string;
  };
  name: string;
  version: string;
}

export class CommonService extends BaseService {
  constructor(
    private readonly backend: AxiosStatic,
    private readonly config: ConfigService,
    private readonly state: State,
  ) {
    super(authService);
  }

  async getHealth(): Promise<{
    statusCode: number;
    health?: HealthCheck;
  }> {
    try {
      const user = await this.checkLoginStatus();
      if (user) {
        this.state.user = user;
      }
      const healthResponse: AxiosResponse<HealthCheck> = await this.backend.get(
        `${this.config.REACT_APP_BACKEND_API_URL}/healthz`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        },
      );

      return { statusCode: 200, health: healthResponse.data };
    } catch (err: any) {
      // this.state.error = err;
      console.warn(err);
      delete this.state.user;
      return { statusCode: 400 };
    }
  }
}
